<template>
    <transition name="slide-down">
        <div v-if="show" class="notification" :class="color">
        <div class="text">{{ text }}
        </div>
        <button @click="closeNotification" class="delete"></button>

        </div>
    </transition>
  </template>
  
  <script>
  export default {
    props: {
        show:{
            type: Boolean,
            default: false,
      },
      text: {
        type: String,
        default: 'This is a notification',
      },
      color: {
        type: String,
        default: '#f8d7da',
      },
    },
    updated() {
        console.log('updated NOTIFICATION!')
    if (this.show) {
      setTimeout(() => {
        this.$emit('closeNotification');
      }, 3000);
    }
    },
    methods: {
      closeNotification() {
        this.$emit('closeNotification');
      },
    },
  };
  </script>
  
  <style scoped>
  .notification {
    position: fixed;
    top: 10%;
    left: 50%;
    width: 400px;
    z-index: 1000;
    transform: translateX(-50%);
    border-radius: 10px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
  }
  .text{
    padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  }
  .danger{
    background-color: #f14668;
    color:#fff;
  }
  .info{
    background-color: #1073EB;
    color: #fff;
  }
.warning{
    background-color: #ffe08a;
    color: rgba(0,0,0,.7);
}
.notification>.delete {
    right: 0.5rem;
    position: absolute;
    top: 0.5rem;
}
.delete{
    right: 0.5rem;
    position: absolute;
    top: 0.5rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: rgba(10,10,10,.2);
    border: none;
    border-radius: 9999px;
    cursor: pointer;
    pointer-events: auto;
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 0;
    height: 20px;
    max-height: 20px;
    max-width: 20px;
    min-height: 20px;
    min-width: 20px;
    outline: 0;
    position: relative;
    vertical-align: top;
    width: 20px;
}
.delete::before{
    background-color: #fff;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
}
.delete::before{
    height: 2px;
    width: 50%;
}
.delete::after{
    height: 50%;
    width: 2px;
}
.delete::after{
    background-color: #fff;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
}

.slide-down-enter-active
{
  animation: slide-down 0.4s ease;
}

@keyframes slide-down {
  0% {
    transform: translateX(-50%) translateY(-100%) ;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
    .notification {
        width: 80%;

    }
}
  </style>