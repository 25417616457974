<template>
   <div class="modal__content__box">
      <div class="modal__content">
         <div class="item__modal">
            <Typography weigth="weigth" :size="'bigger'">Enter your coupon number</Typography>
            <Typography v-if="err" weigth="weigth" class="error"
               >This coupon code is invalid or has expired.</Typography
            >
         </div>
         <form action="" @submit.prevent="submitHandler">
            <div class="input__prising_modal">
               <SWInput v-model="valueInput" ref="input" @focus="err = null" />
            </div>

            <div class="button_check__modal">
               <SWButton :disabled="!valueInput" variant="primary" type="submit" size="big">Apply</SWButton>
            </div>
            <div class="button_skip__modal">
               <SWButton :isCurrentClass="true" variant="secondary" size="big" @click="$emit('onCancel')"
                  >Cancel</SWButton
               >
            </div>
         </form>
      </div>
      <Notification
         :show="notificationShowed"
         :text="notificationText"
         :color="notificationColor"
         @closeNotification="closeNotification"
      />
   </div>
</template>

<script>
import Typography from './UI/Typography.vue';
import { SWButton, SWInput } from 'sw-ui-kit';
import Notification from '../components/UI/Notification.vue';
export default {
   components: {
      Typography,
      SWButton,
      SWInput,
      Notification,
   },

   data() {
      return {
         isOpen: false,
         valueInput: '',
         err: null,
         notificationText: '',
         notificationColor: '',
         notificationShowed: false,
      };
   },

   mounted() {
      if (sessionStorage.getItem('isShowImprovePrompt')) {
         try {
            this.isOpen = JSON.parse(sessionStorage.getItem('isShowImprovePrompt'));
         } catch (e) {
            this.isOpen = true;
         }
      } else {
         this.isOpen = true;
      }

      if (this.$refs.input) {
         this.$nextTick(() => {
            this.$refs.input?.$el.querySelector('input').focus();
         });
      }
   },

   methods: {
      showNotification(text, color) {
         this.notificationShowed = true;
         this.notificationText = text;
         this.notificationColor = color;
      },
      closeNotification() {
         this.notificationShowed = false;
      },
      onSkip() {
         sessionStorage.setItem('isShowImprovePrompt', false);
         this.isOpen = false;
      },
      submitHandler() {
         this.applyPromocode(this.valueInput);
      },
      async applyPromocode(code) {
         try {
            const promocode = await this.$http.get(`payment/promocode?code=${code}`);
            console.log('🚀 ~ applyPromocode ~ promocode:', promocode);

            this.$emit('couponAdded', promocode.data[0]);
            this.$emit('onCancel');
         } catch (err) {
            //this.err = err.data.message;
            this.passwordRequired = true;
            this.showChangePasswordInput = true;
            this.showOnlyNewPasswordRequired = true;
            this.showNotification('Coupon not valid or expired', 'danger');
            return;
            //this.$emit('onCancel');
         }
      },
   },
};
</script>

<style scoped>
.modal__content__box {
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   color: var(--font-color-black);
}
.modal__content {
   display: flex;
   flex-direction: column;
   width: 340px;
}

.input__prising_modal {
   margin-bottom: 40px;
}
.modal__contentModal {
   max-width: 500px;
}
.button_check__modal {
   width: 100%;
}
.button_skip__modal {
   width: 100%;
}

.item__modal {
   margin-bottom: 32px;
}
.paragraf__modal {
   margin-bottom: 32px;
}
.button_check__modal {
   margin-bottom: 18px;
}
.button_skip__modal {
   margin-bottom: 40px;
}
.checkbox__modal {
   margin-right: 50%;
}

.error {
   margin-top: 10px;
   color: var(--text-alert);
}
</style>
