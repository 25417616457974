<template>
   <div class="modal__container" v-if="value">
      <div class="modal__wrapper" ref="backdrop" tabindex="0" @keydown.esc="onClose" @click="onClose">
         <div class="modal__content" @click.stop>
            <slot><Typography size="small" weigth="weigth" lineheigth="biggerHeigth"></Typography></slot>
         </div>
      </div>
   </div>
</template>

<script>
import Typography from './Typography.vue';

export default {
   components: {
      Typography,
   },
   props: {
      value: {},
   },
   mounted() {
      if (this.value) {
         this.$nextTick(() => {
            this.$refs.backdrop.focus();
         });
      }
   },
   watch: {
      value() {
         if (this.value) {
            this.$nextTick(() => {
               this.$refs.backdrop.focus();
            });
         }
      },
   },
   methods: {
      onClose() {
         this.$emit('input', false);
      },
   },
   emits: ['close', ''],
};
</script>

<style scoped>
.modal__content {
   margin: 0px auto;
   padding: 56px 0px 20px 0px;
   transition: all 0.3s ease;
   max-width: 580px;
   text-align: center;
   border-radius: 10px;
   background-color: var(--bg-modal-dark);
}
.modal__wrapper {
   display: table-cell;
   vertical-align: middle;
}
.modal__container {
   position: fixed;
   z-index: 9998;
   top: 0;
   left: 0;
   display: table;
   transition: opacity 0.3s ease;
   width: 100%;
   height: 100%;
   background-color: var(--bg--modal-rgba);
}
</style>
