<template>
   <transition>
      <div class="container__pricing">
         <div class="descktop_block">
            <router-link class="header__pricing" :to="{ name: 'setting' }">
               <div class="img__pricing">
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                     <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.57391 12.4252C7.30054 12.6986 6.85732 12.6986 6.58396 12.4252L1.15341 6.99466C0.880043 6.72129 0.880043 6.27808 1.15341 6.00471L6.58396 0.574165C6.85732 0.300798 7.30054 0.300798 7.57391 0.574165L8.17495 1.17521C8.44831 1.44857 8.44831 1.89179 8.17495 2.16516L4.96484 5.37526L16.2482 5.37526C16.594 5.37526 16.875 5.67788 16.875 6.05026V6.95026C16.875 7.32263 16.594 7.62526 16.2482 7.62526L4.96599 7.62526L8.17495 10.8342C8.44831 11.1076 8.44831 11.5508 8.17495 11.8242L7.57391 12.4252Z"
                     />
                  </svg>
               </div>
               <div class="back__pricing">
                  <Typography :weigth="'weigth'" :size="'bigger'"> Pricing</Typography>
               </div>
            </router-link>
         </div>
         <div class="mobail_block">
            <router-link class="header__pricing" :to="{ name: 'setting' }">
               <div class="img__pricing">
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                     <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.57391 12.4252C7.30054 12.6986 6.85732 12.6986 6.58396 12.4252L1.15341 6.99466C0.880043 6.72129 0.880043 6.27808 1.15341 6.00471L6.58396 0.574165C6.85732 0.300798 7.30054 0.300798 7.57391 0.574165L8.17495 1.17521C8.44831 1.44857 8.44831 1.89179 8.17495 2.16516L4.96484 5.37526L16.2482 5.37526C16.594 5.37526 16.875 5.67788 16.875 6.05026V6.95026C16.875 7.32263 16.594 7.62526 16.2482 7.62526L4.96599 7.62526L8.17495 10.8342C8.44831 11.1076 8.44831 11.5508 8.17495 11.8242L7.57391 12.4252Z"
                     />
                  </svg>
               </div>
               <div class="back__pricing">Pricing</div>
            </router-link>
         </div>
         <div class="content__pricing transition-card">
            <div class="title__pricing">
               <Typography :weigth="'weigth'" :size="'biggest'">Select a plan</Typography>
            </div>

            <div class="block__card" v-if="plans">
               <div
                  v-for="(plan, index) in plans"
                  :key="index"
                  class="card__pricing margin"
                  :class="{ trialSubscription: isProductMatch(plan.product.id) }"
               >
                  <div class="title__card">
                     <Typography :weigth="'weigth'" :size="'bigger'">{{ plan.product.name }}</Typography>
                  </div>
                  <div class="title__card_cost">
                     <div
                        class="title_card_cost_price"
                        :class="{
                           couponAppliedCard:
                              isCouponApplied() &&
                              (productsCouponAppliesTo.length > 0
                                 ? productsCouponAppliesTo.includes(plan.product.id)
                                 : productsCouponAppliesTo.length === 0),
                        }"
                     >
                        <Typography
                           :weigth="'weigth'"
                           :class="{
                              couponApplied:
                                 isCouponApplied() &&
                                 (productsCouponAppliesTo.length > 0
                                    ? productsCouponAppliesTo.includes(plan.product.id)
                                    : productsCouponAppliesTo.length === 0) &&
                                 !isProductMatch(plan.product.id),
                           }"
                           :size="'biggest'"
                           class="sum__pricing"
                        >
                           $ {{ makeFullPrice(plan) }}
                        </Typography>
                        <Typography
                           :weigth="'weigth'"
                           :size="'small'"
                           class="time__pricing"
                           :class="{
                              couponAppliedPrefix:
                                 isCouponApplied() &&
                                 (productsCouponAppliesTo.length > 0
                                    ? productsCouponAppliesTo.includes(plan.product.id)
                                    : productsCouponAppliesTo.length === 0) &&
                                 !isProductMatch(plan.product.id),
                           }"
                           >/{{ makeRecurringPrefix(plan.interval, plan.interval_count) }}</Typography
                        >
                     </div>
                     <div
                        class="title_card_cost_price"
                        v-if="
                           isCouponApplied() &&
                           (productsCouponAppliesTo.length > 0
                              ? productsCouponAppliesTo.includes(plan.product.id)
                              : productsCouponAppliesTo.length === 0) &&
                           !isProductMatch(plan.product.id)
                        "
                     >
                        <Typography
                           :size="'small'"
                           class=""
                           :class="{
                              couponAppliedDiscounted:
                                 isCouponApplied() &&
                                 (productsCouponAppliesTo.length > 0
                                    ? productsCouponAppliesTo.includes(plan.product.id)
                                    : productsCouponAppliesTo.length === 0),
                           }"
                        >
                           $ {{ makePrice(plan) }}
                        </Typography>
                        <Typography
                           :weigth="'weigth'"
                           :size="'small'"
                           class=""
                           :class="{
                              couponAppliedDiscountedPrefix:
                                 isCouponApplied() &&
                                 (productsCouponAppliesTo.length > 0
                                    ? productsCouponAppliesTo.includes(plan.product.id)
                                    : productsCouponAppliesTo.length === 0) &&
                                 !isProductMatch(plan.product.id),
                           }"
                           >/{{ makeRecurringPrefix(plan.interval, plan.interval_count) }}</Typography
                        >
                     </div>
                     <div
                        class="dailyPrice"
                        v-if="
                           isCouponApplied() &&
                           (productsCouponAppliesTo.length > 0
                              ? productsCouponAppliesTo.includes(plan.product.id)
                              : productsCouponAppliesTo.length === 0) &&
                           !isProductMatch(plan.product.id)
                        "
                     >
                        <Typography :size="'small'" class="dailyPriceText">
                           Only $<span class="dailyPriceNumber">{{ makeDailyPrice(plan) }}</span> per day</Typography
                        >
                        <Typography :size="'small'" class="dailyPriceText">
                           You save $<span class="dailyPriceNumber">{{ makeSavePrice(plan) }}</span></Typography
                        >
                     </div>
                  </div>
                  <div class="text__card">
                     <Typography>In this subscription you get</Typography>
                     <Typography></Typography>
                     <Typography>{{ plan.product.description }}.</Typography>
                  </div>
                  <div class="btn__card" v-if="!isProductMatch(plan.product.id)">
                     <div>
                        <SWButton
                           v-if="customer.hasSuccessfullTransaction"
                           class="padding__btn"
                           fullWidth
                           @click="subscribe(plan)"
                        >
                           <Typography class="colorButton cancelWarningHover" :size="'small'">Subscribe</Typography>
                        </SWButton>
                        <div v-if="userSubscription" class="currentSubscriptionWarning">
                           <span>Your current subscription will be canceled</span>
                           <img class="warnPng" src="../../../assets/img/warning.png" alt="" />
                        </div>
                     </div>
                     <SWButton
                        v-if="plan.product.metadata.trialDays && !customer.hasSuccessfullTransaction"
                        class="padding__btn"
                        fullWidth
                        @click="subscribe(plan)"
                     >
                        <Typography class="colorButton" :size="'small'"
                           >Try
                           {{ makeTrialPeriod(plan.interval, plan.interval_count) }}
                           - days free trial</Typography
                        >
                     </SWButton>
                  </div>
                  <div class="btn__card" v-if="isProductMatch(plan.product.id)">
                     <div class="currentSubscriptionStub">
                        <img class="checkPng" src="../../../assets/img/check.png" alt="" />
                        <span class="currentPriceText"> My current price </span>
                     </div>
                  </div>
               </div>
            </div>

            <ModalCoupon v-model="isModal"
               ><BodyModalCupon @onCancel="isModal = false" @couponAdded="onCouponAdded"
            /></ModalCoupon>

            <div class="addCoupon">
               <button @click="openModal" class="button__coupon">
                  <div class="img__coupon">
                     <img src="../../../assets/img/KrestickAddCupon.svg" alt="" />
                  </div>
                  Add your coupon
               </button>
            </div>
            <div v-if="customer" class="addCoupon">
               <button @click="createCustomerPortalSession" class="button__coupon">
                  <div class="img__coupon">
                     <img src="../../../assets/img/KrestickAddCupon.svg" alt="" />
                  </div>
                  Manage subscription
               </button>
            </div>
            <div class="plashkaCrutch">
               <div v-for="(promocode, indexPublic) in promocodes.public" :key="promocode.id" class="plashka__pricing">
                  <div class="plashka">
                     <div class="plashka__block">
                        <div class="img__persent"><img src="../../../assets/img/persentPricing.svg" alt="" /></div>
                        <div class="text__plashka">
                           <Typography class="colorCurrent" :size="'big'" :weigth="'medium'"
                              >{{ promocode.coupon.name }} - {{ makeDiscountValue(promocode) }}% off</Typography
                           >
                        </div>
                     </div>
                     <div class="plashka_controls">
                        <div class="plashka_apply">
                           <SWButton @click="applyDiscount(promocode)"> Apply </SWButton>
                        </div>
                        <div class="plashka__img">
                           <button @click="removePublicDiscountFromList(promocode, indexPublic)">
                              <img src="../../../assets/img/krestick.svg" alt="" />
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
               <div
                  v-for="(promocodePersonal, indexPersonal) in promocodes.personal"
                  :key="promocodePersonal.id"
                  class="plashka__pricing"
               >
                  <div class="plashka">
                     <div class="plashka__block">
                        <div class="img__persent"><img src="../../../assets/img/persentPricing.svg" alt="" /></div>
                        <div class="text__plashka">
                           <Typography class="colorCurrent" :size="'big'" :weigth="'medium'"
                              >{{ promocodePersonal.coupon.name }} - {{ makeDiscountValue(promocodePersonal) }}%
                              off</Typography
                           >
                        </div>
                     </div>
                     <div class="plashka_controls">
                        <div class="plashka_apply">
                           <SWButton @click="applyDiscount(promocodePersonal)"> Apply </SWButton>
                        </div>
                        <div class="plashka__img">
                           <button @click="removePersonalDiscountFromList(promocodePersonal, indexPersonal)">
                              <img src="../../../assets/img/krestick.svg" alt="" />
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <Notification
            :show="notificationShowed"
            :text="notificationText"
            :color="notificationColor"
            @closeNotification="closeNotification"
         />
      </div>
   </transition>
</template>

<script>
import {} from 'sw-ui-kit';
import Typography from '../../../components/UI/Typography.vue';
import ModalCoupon from '../../../components/UI/ModalCoupon.vue';
import BodyModalCupon from '../../../components/BodyModalCupon.vue';
import { SWButton } from 'sw-ui-kit';
//import log from '../../../logger/logger';
import Notification from '../../../components/UI/Notification.vue';
export default {
   components: { Typography, ModalCoupon, BodyModalCupon, SWButton, Notification },
   data() {
      return {
         CloseWindow: true,
         isOpen: false,
         isModal: false,
         coupon: null,
         discountPercentOff: 0,
         products: null,
         plans: null,
         discount: null,
         productsCouponAppliesTo: [],
         notificationText: '',
         notificationColor: '',
         notificationShowed: false,
      };
   },

   created() {
      //await this.getProducts();
      // this.getDiscount();
   },

   async mounted() {
      // await this.getUserSubscription();

      /*   this.userSubscription = this.$store.getters['customer/getSubscription'];
      this.promocodes =  this.$store.getters['customer/getPromocodes']; */
      await this.getPlans();

      /*  if (sessionStorage.getItem('isShowImprovePrompt')) {
         try {
            this.isOpen = JSON.parse(sessionStorage.getItem('isShowImprovePrompt'));
         } catch (e) {
            this.isOpen = true;
         }
      } else {
         this.isOpen = true;
      } */
  //    log(this.$http, { message: 'visited page /settings/pricing', level: 'info' });
      console.log(this.userSubscription);
      console.log(this.promocodes);
   },
   /*  updated(){
      this.applyAutoDiscount();

   }, */

   methods: {
      showNotification(text, color) {
         this.notificationShowed = true;
         this.notificationText = text;
         this.notificationColor = color;
      },
      closeNotification() {
         this.notificationShowed = false;
      },
      onCouponAdded(promocode) {
         console.log('🚀 ~ onCouponAdded ~ coupon:', promocode);
         // this.coupon = coupon;
         this.applyDiscount(promocode);
      },
      onSkip() {
         sessionStorage.setItem('isShowImprovePrompt', false);
         this.isOpen = false;
      },
      openModal() {
         this.isModal = true;
      },

      async subscribe(plan) {
         try {
            let res = null;
            if (
               this.coupon &&
               (this.productsCouponAppliesTo.length > 0
                  ? this.productsCouponAppliesTo.includes(plan.product.id)
                  : this.productsCouponAppliesTo.length === 0)
            ) {
               res = await this.$http.post('payment/create-session', {
                  plan: [plan],
                  discounts: [{ promotion_code: this.coupon }], //! use coupon id instead of promotion_code
               });
            } else {
               res = await this.$http.post('payment/create-session', {
                  plan: [plan],
               });
            }

            console.log('🚀 ~ file: Pricing.vue:127 ~ subscribe ~ res', res);
            window.location.href = res?.data;
         } catch (e) {
            this.passwordRequired = true;
            this.showChangePasswordInput = true;
            this.showOnlyNewPasswordRequired = true;
            this.showNotification(`Coudn't create session`, 'danger');
         }
      },

      async changePlan(plan) {
         try {
            let res = null;
            if (
               this.coupon &&
               (this.productsCouponAppliesTo.length > 0
                  ? this.productsCouponAppliesTo.includes(plan.product.id)
                  : this.productsCouponAppliesTo.length === 0)
            ) {
               res = await this.$http.post('payment/create-session', {
                  plan: [plan],
                  discounts: [{ promotion_code: this.coupon }], //! use coupon id instead of promotion_code
               });
            } else {
               res = await this.$http.post('payment/create-session', {
                  plan: [plan],
               });
            }

            console.log('🚀 ~ file: Pricing.vue:127 ~ subscribe ~ res', res);
            window.location.href = res?.data;
         } catch (e) {
            this.passwordRequired = true;
            this.showChangePasswordInput = true;
            this.showOnlyNewPasswordRequired = true;
            this.showNotification(`Coudn't create session`, 'danger');
         }
      },

      async cancelSubscription() {
         try {
            let res = await this.$http.post('payment/cancel-subscription');
            console.log('🚀 ~ file: Pricing.vue:127 ~ subscribe ~ res', res);
            window.location.href = res?.data;
         } catch (e) {
            this.passwordRequired = true;
            this.showChangePasswordInput = true;
            this.showOnlyNewPasswordRequired = true;
            this.showNotification(`Coudn't create session`, 'danger');
         }
      },

      async createCustomerPortalSession() {
         try {
            let res = await this.$http.post('payment/create-customer-portal-session');
            console.log('🚀 ~ file: Pricing.vue:127 ~ subscribe ~ res', res);
            window.location.href = res?.data;
         } catch (e) {
            this.passwordRequired = true;
            this.showChangePasswordInput = true;
            this.showOnlyNewPasswordRequired = true;
            this.showNotification(`Coudn't create session`, 'danger');
         }
      },

      async getPlans() {
         try {
            let res = await this.$http.get('payment/plans');
            console.log('🚀 ~ file: Pricing.vue:221 ~ getPlans ~ res:', res.data.plans);
            this.plans = res.data.plans;
         } catch (e) {
            console.log(e);
         }
      },

      async getProducts() {
         try {
            let res = await this.$http.get('payment/products');
            console.log('🚀 ~ file: Pricing.vue:221 ~ getSubscriptions ~ res:', res.data.products);
            this.products = res.data.products.filter(product => product.active && product.default_price); //! need to filter because product can be without default_price
         } catch (e) {
            console.log(e);
         }
      },
      async getUserSubscription() {
         try {
            let res = await this.$http.get('payment/user-subscription');
            console.log('🚀 ~ file: ', res.data);
            this.userSubscription = res.data[0]?.plan ?? { product: null };
            console.log('🚀 ~ getUserSubscription ~ userSubscription:', this.userSubscription);
         } catch (e) {
            console.log(e);
         }
      },
      makeFullPrice(price) {
         return (price.amount / 100).toFixed();
      },

      makePrice(price) {
         console.log('🚀 ~ makePrice ~ price:', price);
         if (
            this.coupon &&
            this.discountPercentOff > 0 &&
            (this.productsCouponAppliesTo.includes(price.product.id) || this.productsCouponAppliesTo.length === 0)
         ) {
            return ((price.amount * (1 - this.discountPercentOff / 100)) / 100).toFixed();
         }
         return (price.amount / 100).toFixed();
      },
      makeSavePrice(price) {
         let days = 0;

         switch (price.interval) {
            case 'week':
               days = 7 * price.interval_count;
               break;
            case 'month':
               days = 30 * price.interval_count;
               break;
            default:
               days = 0;
         }
         if (
            this.coupon &&
            this.discountPercentOff > 0 &&
            (this.productsCouponAppliesTo.includes(price.product.id) || this.productsCouponAppliesTo.length === 0)
         ) {
            return ((price.amount - price.amount * (1 - this.discountPercentOff / 100)) / 100).toFixed();
         }
         return (price.amount / 100 / days).toFixed();
      },
      makeDailyPrice(price) {
         let days = 0;

         switch (price.interval) {
            case 'week':
               days = 7 * price.interval_count;
               break;
            case 'month':
               days = 30 * price.interval_count;
               break;
            default:
               days = 0;
         }
         if (
            this.coupon &&
            this.discountPercentOff > 0 &&
            (this.productsCouponAppliesTo.includes(price.product.id) || this.productsCouponAppliesTo.length === 0)
         ) {
            return ((price.amount * (1 - this.discountPercentOff / 100)) / days / 100).toFixed();
         }
         return (price.amount / 100 / days).toFixed();
      },
      makeRecurringPrefix(name, count) {
         const replaced = name
            .replace(/weekly/i, 'week')
            .replace(/monthly/i, 'mo')
            .replace(/half-year/i, 'hf')
            .replace(/annual/i, 'y');

         if (replaced === 'month' && count === 6) {
            return 'hf';
         }
         const matched = replaced.match(/week|mo|hf|y/i);
         return matched ? matched[0] : '';
      },
      makeTrialPeriod(name, count) {
         const replaced = name.replace(/weekly/i, 'week').replace(/monthly/i, 'mo');

         switch (replaced) {
            case 'week':
               return 3;
            case 'month':
               if (count === 6) {
                  return 7;
               } else {
                  return 5;
               }
            default:
               return 0;
         }
      },

      /*  isPromocodeAvailable() {
         return this.promocodes?.public?.length > 0 ? true : false;
      }, */

      makeDiscountValue(promocode) {
         console.log('🚀 ~ makeDiscountValue ~ promocode:', promocode);
         return promocode.coupon.percent_off;
      },

      isProductMatch(product) {
         return this.userSubscription?.plan?.product ? this.userSubscription.plan.product === product  && this.userSubscription.status === 'trialing': false;
      },
      applyDiscount(promocode) {
         this.discountPercentOff = promocode.coupon.percent_off;
         this.coupon = promocode.id;
         this.productsCouponAppliesTo = promocode.coupon?.applies_to?.products ?? [];
         this.CloseWindow = false;
      },
      removePublicDiscountFromList(promocode, index) {
         if (this.coupon === promocode.id) {
            this.coupon = null;
            this.discountPercentOff = 0;
         }
         console.log('', this.promocodes);
         const before = this.promocodes.public.slice(0, index);
         const after = this.promocodes.public.slice(index + 1);
         const sliced = [...before, ...after];

         this.$store.commit('customer/UPDATE_PROMOCODES', { public: sliced, personal: this.promocodes.personal });
      },
      removePersonalDiscountFromList(promocode, index) {
         if (this.coupon === promocode.id) {
            this.coupon = null;
            this.discountPercentOff = 0;
         }
         console.log('', this.promocodes);
         const before = this.promocodes.personal.slice(0, index);
         const after = this.promocodes.personal.slice(index + 1);
         const sliced = [...before, ...after];

         this.$store.commit('customer/UPDATE_PROMOCODES', { public: this.promocodes.public, personal: sliced });
      },
      isCouponApplied() {
         return this.coupon ? true : false;
      },
   },

   computed: {
      userSubscription() {
         return this.$store.getters['customer/getSubscription'];
      },
      promocodes() {
         return this.$store.getters['customer/getPromocodes'];
      },
      customer() {
         return this.$store.getters['customer/getCustomer'];
      },
   },
};
</script>

<style scoped>
.plashka_controls {
   display: flex;
   align-items: center;

   justify-content: space-between;
   width: 120px;
}
.padding__btn {
   padding: 15px 24px;
}
.container__pricing {
   max-width: 1127px;

   background-color: var(--surface);
   color: var(--font-color-primary);
   width: 100%;
   margin: 0 auto;
}
.default {
   font-size: 14px;
}
.block__card {
   display: flex;
   margin-bottom: 60px;
   background-color: var(--surface);
}
.content__pricing {
   display: flex;
   flex-direction: column;
   margin-top: 20px;
   justify-content: center;
   align-items: center;
   max-width: 1127px;
   width: 1127px;
   width: 100%;
}
.header__pricing {
   display: flex;
   font-size: 24px;
   cursor: pointer;
   font-weight: 700;
   line-height: 31.2px;
}
.title__pricing {
   font-size: 48px;
   font-weight: 700;
   line-height: 62.4px;
   margin-bottom: 42px;
}
.img__pricing {
   width: 20px;
   height: 12.26px;
   margin-right: 33px;
}
.btn__card {
   max-width: 358px;
   width: 100%;
   padding: 40px 10px 32px 10px;
}
.mobail_block {
   display: none;
}
.mobail_block .back__pricing {
   text-align: center;
}

.descktop_block {
   display: block;
}
.card__pricing {
   max-width: 358px;
   width: 358px;
   max-height: 461px;
   display: flex;

   border-radius: 10px;
   padding: 32px 4px 9px 5px;
   box-shadow: 0px 0px 8px 0px var(--box-shadow);
   flex-direction: column;
   align-items: center;
   justify-content: center;
   background-color: var(--bg-modal-dark);
}
.margin {
   margin-right: 32px;
}
.colorCurrent {
   color: #fff !important;
}
.text__card {
   text-align: center;
   font-size: 16px;
   font-weight: 400;
   line-height: 20.8px;
}
.bold {
   font-weight: 800;
}
.title__card {
   font-size: 24px;
   font-weight: 700;
   margin-bottom: 24px;
   line-height: 31, 2px;
}
.title__card_cost {
   display: flex;
   padding-bottom: 24px;
   transform: translateX(19px);
   font-weight: 700;
   flex-direction: column;
}
.title_card_cost_price {
   display: flex;
}
/* 
.block_three {
   transform: none;
} */
.title__card_cost .sum__pricing {
   font-size: 56px;
   line-height: 72.8px;
}
.title__card_cost .time__pricing {
   font-size: 20px;
   line-height: 26px;
   transform: translateY(33px);
}
.button__coupon {
   display: flex;
   align-items: center;
   justify-content: center;
   border: none !important;
}

.button__coupon:disabled {
   cursor: not-allowed;
   opacity: 0.9;
}

.addCoupon {
   margin-bottom: 46px;
   color: var(--shape-hover);
   font-size: 16px;
   font-weight: 400;
}
.img__coupon {
   width: 16px;
   height: 16px;
   margin-right: 8px;
}
.activePricing {
   border: 1px solid var(--shape-hover);
}
.activePricing .btn__card {
   display: none;
}
.btn__cardActivePricing {
   max-width: 358px;
   width: 100%;
   padding: 40px 10px 32px 10px;
}
.ative__btn {
   display: flex;
   align-items: center;
   justify-content: center;
}
button:disabled {
   border: 1px solid var(--shape-hover);
   color: var(--shape-hover);
}
.secondary:disabled {
   background-color: #fff;
}
.img_cardActivePricing {
   width: 14px;
   height: 14px;
   margin-right: 10px;
}
.plashka {
   margin-top: 10px;
   width: 100%;
   color: var(--font-color-white);
   border-radius: 10px;
   background-color: var(--shape-hover);
   width: 1127px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0px 16px;
   height: 64px;
}
.plashka__pricing {
   max-width: 1127px;
}
.img__persent {
   width: 32px;
   height: 32px;
   margin-right: 20px;
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.plashka__block {
   display: flex;
   align-items: center;
}
.img__pricing svg {
   fill: var(--font-color-primary);
}
.colorButton {
   color: var(--color-button-darck-team) !important;
}

@media screen and (max-width: 1127px) {
   .block__card {
      flex-direction: column;
   }
   .margin {
      margin-right: 0;
      margin-bottom: 32px;
   }
   .plashka {
      max-width: 1127px;
      width: 600px;
   }
}
@media screen and (max-width: 1120px) {
   /* .container__pricing {
      overflow-y: auto;
      height: 78svh;
   } */
}
@media screen and (max-width: 650px) {
   /* .mobail_block {
      position: fixed;
      top: -15px;
      left: 0;
      width: 100%;
      background-color: var(--bg-install-element);
      padding-bottom: 20px;
   } */
   .img__pricing {
      padding-top: 10px;
      padding-left: 10px;
   }
   /* .back__pricing p {
      font-size: 20px !important;
      padding-left: 110px;

      padding-top: 20px;
   } */
   .container__pricing {
      position: relative;
   }
   .descktop_block {
      display: none;
   }
   .header__pricing {
      position: relative;
      width: 100%;
   }
   /* .show-on-mobile {
      display: none;
   } */
   .link__layout {
      margin-top: 0;
   }
   .img__pricing {
      position: absolute;
      left: 10px;
      top: 17px;
   }
   .back__pricing {
      margin: 0 auto;
      padding: 20px 0px 20px 10px;
      transform: translateY(6px);
      font-size: 20px;
   }
   .mobail_block {
      display: block;
      position: fixed;
      width: 100%;
      top: 23px;
      background-color: var(--bg-install-element);
      left: 50%;
      transform: translate(-50%, -50%);
   }
   .plashka {
      width: 100%;
      border-radius: 0;
   }
   .plashkaCrutch {
      position: fixed;
      width: 100%;
      border-radius: 0;
      top: 120px;
      left: 50%;
      transform: translate(-50%, -50%);
   }
   .plashka__img {
      margin-right: 15px;
   }
   .title__pricing {
      margin-bottom: 20px;
      margin-top: 60px;
   }
   .title__pricing p {
      font-size: 24px !important;
   }
}
@media screen and (max-width: 420px) {
   .container_layout {
      padding: 0;
   }
   .container__pricing {
      margin-top: 40px;
   }
   .plashka {
      width: 320px;
      width: 100%;
   }
   .card__pricing {
      width: auto;
   }
   .title__pricing {
      font-size: 24px;
      margin-bottom: 40px;
   }
   .img__persent {
      margin-right: 5px;
   }
   .plashka {
      justify-content: space-around;
      padding: 0px 5px;
   }
}

.trialSubscription {
   position: relative;
   border: 1px solid #1073eb;
}
.trialSubscription::after {
   content: 'trial';
   position: absolute;
   top: 0;
   right: 0;
   padding: 5px 10px;
   color: white;
   background-color: #1073eb;
   border-radius: 0 9px 0 5px;
}
.currentSubscriptionStub {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   color: #1073eb;
   font-size: 16px;
   font-weight: 400;
   line-height: 20.8px;
   border: 1px solid #1073eb;
   border-radius: 10px;
   padding-top: 8px;
   padding-bottom: 8px;
}
.checkPng {
   height: 16px;
   width: 16px;
   margin-right: 15px;
}
.couponApplied {
   text-decoration: line-through;
   color: red;
   font-size: 28px !important;
}
.couponAppliedPrefix {
   font-size: 14px !important;
   transform: translateY(30px) !important;
}

.couponAppliedDiscounted {
   font-size: 56px !important;
   font-weight: 700;
}
.couponAppliedDiscountedPrefix {
   font-size: 20px !important;
   transform: translateY(33px);
}
.dailyPrice {
   display: flex;
   flex-direction: column;
   margin-top: 10px;
}
.dailyPriceNumber {
   font-size: 20px;
   font-weight: 700;
   margin-left: 5px;
}

@keyframes fadeIn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}
.transition-card {
   animation: fadeIn 0.5s forwards;
   animation-delay: 0.5s;
   opacity: 0;
}

.padding__btn {
   position: relative;
}
.currentSubscriptionWarning {
   position: absolute;
   font-size: 12px;
   margin-top: 10px;
   display: flex;
   align-items: center;
   visibility: hidden;
}
.warnPng {
   height: 14px;
   width: 14px;
   margin-left: 5px;
}
.padding__btn:hover ~ .currentSubscriptionWarning {
   visibility: visible;
}
</style>
